import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'

import SEO from '../components/seo'

import Slider from '../components/slider'

const IndexPage = () => (
  <Layout>
    <SEO
      // title="MegaTech Way"
      title="Electronic Cable Assembly Manufacturer"
      keywords={[]}
    />

    <Slider />

    <StaticQuery
      query={graphql`
        query {
          wp {
            pageBy(uri: "home") {
              id
              title
              link
              content(format: RAW)
            }
          }
        }
      `}
    >
      {data => {
        return (
          <div>
            <Blocks>
              <div className="inner">
                <Block>
                  <Link to="/an-extension-of-your-design-team">
                    <h3>
                      Design & <span>Engineering</span>
                    </h3>
                    <div className="content">
                      Expertise in engineering and design for manufacturability.
                    </div>
                    <div className="button">Learn more</div>
                  </Link>
                </Block>

                <Block>
                  <Link to="/quality-in-all-we-do">
                    <h3>
                      Commitment to <span>Quality</span>
                    </h3>
                    <div className="content">
                      We meet IATF 16949:2016 and ISO 9001:2015 requirements,
                      are UL and CSA certified, and compliant with IPC-A-620
                      industry standards.
                    </div>
                    <div className="button">Learn more</div>
                  </Link>
                </Block>

                <Block>
                  <Link to="/case_studies">
                    <h3>
                      Application <span>Knowledge</span>
                    </h3>
                    <div className="content">
                      Our program managers and engineering team have experience
                      in solving unique challenges of customers’ custom wire
                      harness and cable needs.
                    </div>
                    <div className="button">Learn more</div>
                  </Link>
                </Block>
                <Block>
                  <Link to="capabilities">
                    <h3>
                      Logistical <span>Reliability</span>
                    </h3>
                    <div className="content">
                      Logistical support to ensure on-time deliveries, every
                      time.
                    </div>
                    <div className="button">Learn more</div>
                  </Link>
                </Block>
              </div>
            </Blocks>

            <Features>
              <div className="inner">
                <div>
                  <img
                    src={'/iso_9001.jpg'}
                    alt="ISO 9001 - IATF 16949 Mega Techway, Inc"
                  />
                </div>
                <div>
                  <img
                    src={'/whma-logo_white.png'}
                    alt="WHMA certified"
                    style={{ height: 65 }}
                  />
                </div>
                <div>
                  <img
                    src={'/ISO-9001-2015-Mega-techway.png'}
                    alt="ISO 9001:2015 Certified Mega Techway, Inc"
                  />
                </div>
                <div>
                  <img src={'/ul.png'} alt="UL/CSA Mega Techway" />
                </div>
              </div>
            </Features>
          </div>
        )
      }}
    </StaticQuery>
    {/* <ContactForm /> */}
  </Layout>
)

const Blocks = styled.section`
  /* background: #f4f4f4; */

  padding: 5rem;

  .title {
    font-weight: 300;
    text-transform: uppercase;
    font-size: 14px;
    text-align: center;
    color: #000;
    letter-spacing: 1.18px;
    position: relative;

    /* margin: 25px 100px; */
    margin-bottom: 3rem;

    &::before {
      height: 1px;
      background: rgba(0, 0, 0, 0.11);
      width: 42%;
      top: 6px;
      right: -20px;
      content: '';
      position: absolute;
    }

    &::after {
      height: 1px;
      background: rgba(0, 0, 0, 0.11);
      width: 42%;

      top: 6px;
      left: -20px;
      content: '';
      position: absolute;
    }
  }

  .inner {
    display: flex;
    max-width: 1380px;
    margin: 0 auto;

    @media (max-width: 700px) {
      flex-direction: column;
    }
  }

  @media (max-width: 700px) {
    padding: 4rem 2rem;
  }
`
const Block = styled.div`
  text-align: center;

  display: flex;
  flex: 1;
  margin: 0 1rem;
  font-size: 16px;

  a {
    color: inherit;
    text-decoration: none;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .content {
    min-height: 120px;
  }

  .button {
    background: #ddd;
    color: #000;

    padding: 6px 24px;
    font-size: 14px;

    font-weight: bold !important;
    border-radius: 3px;
    border: 0;

    margin-top: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  h3 {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    border-top: 2px solid #aaa;
    border-bottom: 2px solid #aaa;
    height: 95px;

    span {
      display: block;
      letter-spacing: 0px;
      margin-top: 5px;
      font-size: 32px;
    }
  }

  @media (max-width: 700px) {
    margin: 2rem 0;
  }
`

const Features = styled.section`
  background: #141b22;
  color: #fff;

  margin: 2rem 0 4rem 0;
  .inner {
    max-width: 1280px;
    margin: 0rem auto;
    padding: 2.5rem 0;

    align-items: center;

    display: flex;
    justify-content: space-between;

    img {
      height: 100px;
    }

    @media (max-width: 700px) {
      flex-direction: column;

      img {
        margin: 2rem 0;
      }
    }
  }

  .alt {
    display: flex;
  }
`

export default IndexPage
